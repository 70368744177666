<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.installations') }}</h5>
                <DataTable
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-installations"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" :showClear="true" v-model="shopsModel" :options="shops" optionLabel="name" :placeholder="$t('search.byShop')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="activeFilter" :options="activeOptions" :placeholder="$t('search.byStatus')" optionLabel="option" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="typeFilter" :options="typeOptions" :placeholder="$t('search.byType')" optionLabel="option" :filter="!this.isMobile()" />
                            </div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="_id" :header="$t('system.id')"></Column>
                    <Column field="installationId" :header="$t('system.installationId')"></Column>
                    <Column field="terminalType" :header="$t('system.terminalType')" :sortable="true">
                        <template #body="{ data }">{{ getTerminalTypeName(data.terminalType) }}</template>
                    </Column>
                    <Column field="shopName" :header="$t('system.shop')" :sortable="true"></Column>
                    <Column field="pcName" :header="$t('system.pcName')" :sortable="true"></Column>
                    <Column field="registrationComments" :header="$t('system.registrationComments')"></Column>
                    <Column field="registeredOn" :header="$t('system.registeredOn')" :sortable="true">
                        <template #body="{ data }">{{ formatCSDateAndTime(data.registeredOn) }}</template>
                    </Column>
                    <Column field="authorizedOn" :header="$t('system.authorizedOn')" :sortable="true">
                        <template #body="{ data }">{{ formatCSDateAndTime(data.authorizedOn) }}</template>
                    </Column>
                    <Column field="active" :header="$t('general.status')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span :class="'custom-badge status-' + data.active">{{ data.active ? $t('general.active') : $t('general.inactive') }}</span>
                        </template>
                    </Column>
                    <Column :exportable="false" class="p-text-right" v-show="hasAccess(availableMenus, editPage)">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: slotProps.data._id })" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoApi: new BoApi(),
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'installations',
            activeFilter: { id: 'all', option: 'All' },
            filtersInitializationDone: false,
            typeFilter: { id: '100', option: 'All' },
            defaultTypeId: '100',
            shops: [],
            shopsModel: { _id: 'all', name: 'All' },
            defaultShopId: 'all',
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
        activeOptions() {
            return [
                {
                    id: 'all',
                    option: this.$t('general.all'),
                },
                {
                    id: 'active',
                    option: this.$t('general.active'),
                },
                {
                    id: 'inactive',
                    option: this.$t('general.inactive'),
                },
            ];
        },
        typeOptions() {
            return [
                {
                    id: '100',
                    option: this.$t('general.all'),
                },
                {
                    id: '2',
                    option: this.$t('system.cashiers'),
                },
                {
                    id: '1',
                    option: this.$t('system.selfBettingTerminals'),
                },
            ];
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    watch: {
        activeFilter() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        typeFilter() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        shopsModel() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
    },
    mounted() {
        this.BoApi.getShops('')
            .then((response) => {
                this.shops = response.data;
                this.shops.unshift(this.shopsModel);
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
        if (this.storeFilterData != undefined) {
            this.sort = this.storeFilterData.data.sort;
            this.activeFilter = this.activeOptions.find((s) => s.id == this.storeFilterData.data.filter);
            this.shopsModel = this.shops.find((s) => s.id == this.storeFilterData.data.shopId);
            this.typeFilter = this.typeOptions.find((s) => s.id == this.storeFilterData.data.type);
            this.readItems(this.storeFilterData.data.page);
        } else {
            this.readItems();
        }
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            const params = {
                per_page: this.perPage,
                page: page,
                sort: this.sort,
                filter: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                terminalType: this.notNullOrEmptyObject(this.typeFilter) ? this.typeFilter.id : this.defaulttypeId,
                shopId: this.notNullOrEmptyObject(this.shopsModel) ? this.shopsModel._id : this.defaultShopId,
            };
            this.BoApi.getTerminals(params)
                .then((response) => {
                    this.items = response.data.data;
                    this.totalPages = response.data.total;
                    this.loading = false;
                    this.filtersInitializationDone = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        composeAndSaveFiltersToStore() {
            let filters = {
                per_page: this.perPage,
                page: this.crtPage,
                sort: this.sort,
                filter: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                type: this.notNullOrEmptyObject(this.typeFilter) ? this.typeFilter.id : this.defaulttypeId,
                shopId: this.notNullOrEmptyObject(this.shopsModel) ? this.shopsModel.id : this.defaultShopId,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        getTerminalTypeName(type) {
            var text = this.typeOptions.find((s) => s.id == type);
            return this.notNullOrEmptyObject(text) ? text.option : '';
        },
    },
};
</script>
